<template>
  <div>
    <div class="overflow-hidden drop-shadow sm:rounded-lg">
      <table
        class="w-full divide-y divide-slate-200"
        aria-describedby="users table"
      >
        <thead class="bg-slate-100">
          <tr>
            <th
              v-for="header in ['Email', 'Role', 'Status', '']"
              :key="'header-' + header"
              class="text-left text-slate-500 px-6 py-3 text-sm font-semibold"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-slate-200 bg-white">
          <tr v-for="user in filteredUsers" :key="'user-' + user.id">
            <td class="py-4 px-6">
              <div>
                <p class="text-slate-700">{{ buildUserFullname(user) }}</p>
                <p class="text-slate-500">{{ user.email }}</p>
              </div>
            </td>
            <td class="py-4 px-3">
              {{
                user.role === Role.ADMIN
                  ? 'Admin'
                  : user.role === Role.USER
                    ? 'User'
                    : 'Reader'
              }}
            </td>
            <td class="py-4 px-2">
              <div>
                <div class="text-sm text-slate-400">
                  {{ user.isDisabled ? 'Inactive' : 'Active' }}
                </div>
                <FloatingMenu>
                  <template #activator="{ show }">
                    <HeadlessSwitch
                      :model-value="!user.isDisabled"
                      :class="[
                        !user.isDisabled ? 'bg-green-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                      ]"
                      @click="show"
                    >
                      <span
                        aria-hidden="true"
                        :class="[
                          !user.isDisabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
                        ]"
                      />
                    </HeadlessSwitch>
                  </template>
                  <template #content="{ hide }">
                    <div class="p-4 w-96 text-[#516A75] border">
                      <div class="rounded-md bg-blue-50 p-4">
                        <div class="flex">
                          <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p
                              v-if="!user.isDisabled"
                              class="text-sm text-blue-700"
                            >
                              Are you sure you want to disable this user?<br />
                            </p>
                            <p v-else class="text-sm text-blue-700">
                              Are you sure you want to enable this user?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <button
                          class="bg-neutral-500 text-white py-1 px-2 mr-1 hover:brightness-110"
                          @click="hide"
                        >
                          Cancel
                        </button>
                        <button
                          class="bg-red-600 text-white py-1 px-2 hover:brightness-125"
                          @click="toggleUserState(user.id!, hide)"
                        >
                          {{ user.isDisabled ? 'Enable' : 'Disable' }}
                        </button>
                      </div>
                    </div>
                  </template>
                </FloatingMenu>
              </div>
            </td>
            <td class="py-4">
              <div class="flex gap-2 text-primary">
                Edit
                <EditUserFloatingMenu
                  :data="{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    role: user.role,
                    userId: user.id!,
                  }"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Switch
      v-model="shouldShowInactive"
      size="lg"
      class="mt-4 text-slate-700 font-medium tracking-tighter"
    >
      Show inactive users
    </Switch>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { Role } from '@core/domain/types/role.type'
import EditUserFloatingMenu from './EditUserFloatingMenu.vue'
import { Switch as HeadlessSwitch } from '@headlessui/vue'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import useUsers from '@app/composables/use-users'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { User } from '@core/domain/models/user.model'
import Switch from '@app/components/Global/Switch.vue'

const shouldShowInactive = ref(false)

const snackbar = useSnackbar()
const loading = useLoading()
const users = useUsers()
users.refresh()

async function toggleUserState(userId: string, cb: () => void) {
  const user = users.users.value.find((u) => u.id === userId)
  try {
    loading.start()
    if (!user?.isDisabled) {
      await users.disableUser(userId)
      snackbar.show(SnackbarState.SUCCESS, 'User successfully disabled')
    } else {
      await users.enableUser(userId)
      snackbar.show(SnackbarState.SUCCESS, 'User successfully enabled')
    }
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

const filteredUsers = computed(() => {
  return shouldShowInactive.value
    ? users.users.value
    : users.users.value.filter((user) => !user.isDisabled)
})

function buildUserFullname(user: User) {
  if (!user) return ''
  let fullName = ''
  if (user.firstName) {
    fullName += user.firstName
  }
  if (user.lastName) {
    fullName += ' ' + user.lastName
  }
  if (!fullName) {
    fullName = user.email
  }
  return fullName
}
</script>
