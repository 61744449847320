<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-ch"
    viewBox="0 0 512 512"
  >
    <g fill-rule="evenodd" stroke-width="1pt">
      <path fill="red" d="M0 0h512v512H0z" />
      <g fill="#fff">
        <path d="M96 208h320v96H96z" />
        <path d="M208 96h96v320h-96z" />
      </g>
    </g>
  </svg>
</template>
