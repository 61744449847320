<template>
  <Modal v-if="isAdmin" dismissible>
    <template #activator="{ show }">
      <button
        class="px-2 py-2"
        :class="[isShown ? 'text-white/80 hover:text-white' : '']"
        @click.stop="show"
      >
        <Cog6ToothIcon class="w-5 h-5" />
      </button>
    </template>
    <template #content>
      <div class="rounded-lg w-[900px]">
        <div class="h-full flex flex-col">
          <div class="h-full w-full flex overflow-hidden">
            <div class="w-full overflow-auto">
              <div
                class="flex p-6 justify-between items-center border-b border-slate-300"
              >
                <p
                  class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
                >
                  Project details
                </p>
              </div>
              <div class="space-y-6 w-full divide-y">
                <div class="p-6 space-y-4">
                  <TextInput
                    label="Project name"
                    :model-value="internalProjectName"
                    type="text"
                    @enter="updateProjectName(project.id!, $event)"
                  >
                    <template #suffix>
                      <div
                        class="inline-flex items-center rounded-sm border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
                      >
                        <ArrowTurnDownLeftIcon class="w-3.5" /> Enter
                      </div>
                    </template>
                  </TextInput>

                  <TextInput
                    label="Contact for incident reporting"
                    placeholder="Insert email address ( contact@company.com )"
                    :model-value="internalContactForComplaint"
                    type="text"
                    :rules="[
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                    @enter="updateSupevisorEmail(project.id!, $event)"
                  >
                    <template #suffix>
                      <div
                        class="inline-flex items-center rounded-sm border shadow-md border-gray-200 px-2.5 font-sans text-xs text-gray-400 py-1 gap-1"
                      >
                        <ArrowTurnDownLeftIcon class="w-3.5" /> Enter
                      </div>
                    </template>
                  </TextInput>
                  <CitationStyleSelection :project="project" />
                </div>
                <div class="p-6 space-y-4">
                  <p class="text-primary font-semibold text-md leading-7">
                    Reviews
                  </p>
                  <div class="flex items-center gap-2 text-md font-medium">
                    <Switch
                      :model-value="project.useReviewsPlanLocking"
                      label="Enable review plan locking"
                      @update:model-value="setReviewsPlanLocking"
                    >
                      Enable review plan locking</Switch
                    >
                  </div>
                </div>

                <div class="p-6 pb-10 space-y-4 text-primary bg-black/10">
                  <div class="flex justify-between">
                    <div class="flex items-center gap-2">
                      <TrashIcon class="w-4 h-4 text-red-500" />
                      <p class="font-semibold text-md leading-none">
                        Delete project
                      </p>
                    </div>
                    <button
                      v-tooltip="
                        deleteProjectConfirmation === CheckboxState.uncheked
                          ? 'Please check the box to confirm project deletion'
                          : ''
                      "
                      :class="{
                        'opacity-40 cursor-not-allowed':
                          deleteProjectConfirmation === CheckboxState.uncheked,
                      }"
                      class="bg-red-500 text-white px-3.5 py-2.5 hover:brightness-95 rounded-md"
                      @click.stop="deleteProject(project.id!)"
                    >
                      Delete Project
                    </button>
                  </div>
                  <div class="bg-slate-100 p-4 rounded-md">
                    <div class="flex gap-2 mb-2">
                      <ExclamationCircleIcon class="w-5 h-5 text-red-500" />
                      <div>
                        <p
                          class="font-bold text-sm leading-[150%] text-red-500"
                        >
                          You are about to delete this project
                        </p>
                        <span
                          class="text-black font-normal text-sm leading-[150%]"
                          >This action deletes the project
                          {{ project.name }} and everything it contains.
                          <br />There is no going back.
                        </span>
                      </div>
                    </div>
                    <div class="flex gap-3 items-center pt-2">
                      <button @click.stop="toggleDeleteProjectConfirmation">
                        <Checkbox v-model="deleteProjectConfirmation" />
                      </button>
                      <p class="text-primary text-sm">
                        Check this box if you understand the risks
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import useProjects from '@app/views/Projects/use-projects'
import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { HttpException } from '@core/exceptions/http.exception'
import { hideAll } from 'tippy.js'
import { computed, ref } from 'vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import ExclamationCircleIcon from '@app/components/Icons/ExclamationCircleIcon.vue'
import Checkbox, {
  CheckboxState,
} from '@app/components/Global/Inputs/Checkbox.vue'
import Switch from '@app/components/Global/Switch.vue'
import useModalManager from '@app/components/Global/Modal/modal-manager'
import CitationStyleSelection from './CitationStyleSelection.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import { Cog6ToothIcon } from '@heroicons/vue/24/solid'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ArrowTurnDownLeftIcon } from '@heroicons/vue/24/outline'

const { user } = injectStrict(AuthKey)
const isAdmin = computed(() => user.value?.role === Role.ADMIN)

const props = defineProps<{
  project: Project
  isShown?: boolean
}>()

const deleteProjectConfirmation = ref<CheckboxState>(CheckboxState.uncheked)

const internalProjectName = ref(props.project.name ?? '')
const internalContactForComplaint = ref(
  props.project.contactForComplaintEmail ?? '',
)

const loading = useLoading()
const snackbar = useSnackbar()
const projects = useProjects()

async function updateProjectName(projectId: Id, name: string): Promise<void> {
  try {
    loading.start()
    await projects.updateName(projectId, name)
    internalProjectName.value = name
    snackbar.success('Project name updated successfully')
  } finally {
    loading.stop()
  }
}

function isEmailValid(email: string): boolean {
  return /.+@.+\..+/.test(email)
}

async function updateSupevisorEmail(
  projectId: Id,
  email: string,
): Promise<void> {
  if (!isEmailValid(email)) {
    return
  }
  try {
    loading.start()
    await projects.updateSupevisorEmail(projectId, email)
    snackbar.success('Contact for incident reporting updated successfully')
    internalContactForComplaint.value = email
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const modalManager = useModalManager()

async function deleteProject(projectId: Id) {
  if (deleteProjectConfirmation.value !== CheckboxState.checked) return
  loading.start()
  try {
    await projects.deleteProject(projectId)
    hideAll()
    modalManager.hideAllModals()
    deleteProjectConfirmation.value = CheckboxState.uncheked
    snackbar.show(SnackbarState.SUCCESS, 'Project deleted successfully')
  } catch (e) {
    snackbar.error('an error has occured')
    throw e
  } finally {
    loading.stop()
  }
}

function toggleDeleteProjectConfirmation() {
  if (deleteProjectConfirmation.value === CheckboxState.checked)
    deleteProjectConfirmation.value = CheckboxState.uncheked
  else deleteProjectConfirmation.value = CheckboxState.checked
}

async function setReviewsPlanLocking(value: boolean) {
  try {
    loading.start()
    await projects.setReviewsPlanLocking(props.project.id!, value)
    snackbar.success('Review plan locking updated')
  } catch (e) {
    snackbar.error('an error has occured')
    throw e
  } finally {
    loading.stop()
  }
}
</script>
