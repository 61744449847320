<template>
  <div
    v-if="review.displayOptions.isSynthesisStageActive.value"
    class="sticky bottom-0 z-50 inline-grid"
    :style="[
      gridStyle,
      {
        height: isExpanded ? '200px' : '50px',
      },
    ]"
  >
    <div class="sticky left-0 z-[51] bottom-0 border-l-[24px] border-white">
      <button
        class="px-4 py-2 gap-2 w-full h-full flex bg-slate-100"
        @click="isExpanded = !isExpanded"
      >
        <div class="flex items-center">
          <ChevronDoubleUpIcon v-if="!isExpanded" class="w-5" />
          <ChevronDoubleDownIcon v-else class="w-5" />
          synthesis
        </div>
      </button>
    </div>
    <div
      v-for="attributeStructure in review.currentAttributesStructure.value"
      :key="attributeStructure.id"
      class="h-full border bg-slate-100"
    >
      <Editor
        :is-expanded="false"
        :provider="review.dataExtractionProvider"
        :user-name="userName"
        :user-color="color"
        :disabled="review.isReviewReadonly.value"
        :field="
          'review-' +
          review.entity.value.id +
          '-synthesis-' +
          attributeStructure.id
        "
        :class="isExpanded ? 'h-[200px] bg-slate-100' : 'h-[50px] bg-slate-100'"
        :placeholder="'Enter the synthesis of ' + attributeStructure.label"
        editor-classes="overflow-auto h-full"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'
import { computed, ref } from 'vue'
import { AuthKey } from '@app/injectionKeys'
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/vue/24/outline'
import Editor from './components/Editor.vue'

defineProps<{
  gridStyle: string
}>()

const review = injectStrict(ReviewKey)
const { user, color } = injectStrict(AuthKey)
const userName = computed(
  () => user.value?.firstName + ' ' + user.value?.lastName,
)

const isExpanded = ref(false)
</script>
