<template>
  <div
    v-if="currentModal === 'customSourceHandSearch'"
    class="w-screen max-w-[644px] p-6 relative pt-2 bg-slate-100 rounded-2xl"
  >
    <div class="flex justify-end">
      <button class="p-2" @click="emit('hide')">
        <XMarkIcon class="w-6 text-slate-400 hover:text-slate-600" />
      </button>
    </div>
    <div class="space-y-4">
      <div class="flex gap-1 items-center">
        <DatabaseSolideIcon class="text-blue-800 w-5 h-5" />
        <div class="text-blue-800 font-semibold text-base">
          Custom source / Hand search
        </div>
      </div>
      <div class="space-y-6 pb-0">
        <div class="flex gap-2 items-center">
          <div class="group grid size-4">
            <input
              id="handsearch"
              v-model="isHandSearch"
              aria-describedby="handsearch-description"
              name="handsearch"
              type="checkbox"
              class="col-start-1 row-start-1 appearance-none rounded-sm border border-gray-300 bg-white checked:border-blue-800 checked:bg-blue-800 indeterminate:border-blue-800 indeterminate:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-800 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
            />
            <svg
              class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                class="opacity-0 group-has-[:checked]:opacity-100"
                d="M3 8L6 11L11 3.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                class="opacity-0 group-has-[:indeterminate]:opacity-100"
                d="M3 7H11"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="text-sm/6">
            <label for="handsearch" class="font-medium text-slate-700 tetx-sm"
              >Use hand search instead</label
            >
          </div>
        </div>

        <div class="flex items-center gap-4 justify-between">
          <TextInput
            v-model="newImportSource.url"
            placeholder="https://www.embase.com/"
            is-required
            :rules="[(v) => !v || isUrlValid(v) || 'Invalid URL']"
            :class="{ 'opacity-50': isHandSearch }"
            :disabled="isHandSearch"
          />
          <img
            v-show="sourceIconUrl"
            class="w-6 h-6"
            :src="sourceIconUrl"
            alt="source logo"
          />
          <TextInput v-model="newImportSource.name" placeholder="Source name" />
        </div>
      </div>
      <button
        class="w-full flex text-sm gap-1 items-center justify-center bg-blue-800 text-white rounded-lg px-4 py-3 font-medium text-center"
        @click="handleButtonClick"
      >
        Add source
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import DatabaseSolideIcon from '@app/components/Icons/DatabaseSolideIcon.vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import useLoading from '@app/composables/use-loading'
import { watchDebounced } from '@vueuse/core'
import { isUrlValid } from '@app/utils/urlValidation'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import axios, { isAxiosError } from 'axios'
import useSnackbar from '@app/composables/use-snackbar'

const emit = defineEmits<(e: 'hide') => void>()
const review = injectStrict(ReviewKey)
const isHandSearch = ref(false)
const currentModal = ref<
  'customSourceHandSearch' | 'handSearch' | 'customSource'
>('customSourceHandSearch')
const loading = useLoading()
const snackbar = useSnackbar()
defineProps<{
  readonly?: boolean
}>()

async function handleButtonClick() {
  loading.start()
  try {
    if (isHandSearch.value) {
      const dataSource =
        review.entity.value.plan?.importPlan.importSources?.find(
          (source) => source.id === BuiltInImportSourceId.HAND_SEARCH,
        )
      if (!dataSource) {
        await enableHandSearch()
      }
    } else if (newImportSource.value.url) {
      await addCustomSource()
    }

    emit('hide')
  } catch (e) {
    if (isAxiosError(e)) {
      const error = e.response?.data
      if (
        error.statusCode === 409 &&
        error.message === 'Import source already exists'
      )
        emit('hide')
      else snackbar.error(error.message)
    } else {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const builtInImportSources = useBuiltInImportSources()
const handSearch = builtInImportSources.findById(
  BuiltInImportSourceId.HAND_SEARCH,
)!

async function enableHandSearch() {
  await review.addCustomImportSourceToPlan({
    id: handSearch.id,
    name: handSearch.name,
    description: '',
    url: '',
    type: handSearch.type,
  })
}

async function addCustomSource() {
  await review.addCustomImportSourceToPlan(newImportSource.value)
}

type NewImportSource = {
  type: ImportSourceType
  name: string
  url: string
  description?: string
}

const newImportSource = ref<NewImportSource>({
  name: '',
  url: '',
  description: '',
  type: ImportSourceType.OTHER_SOURCE,
})

const sourceIconUrl = ref('')

watchDebounced(
  () => newImportSource.value.url,
  async (url) => {
    if (!url || !isUrlValid(url)) {
      sourceIconUrl.value = '/sources-icons/custom-source.png'
    } else {
      sourceIconUrl.value = `https://www.google.com/s2/favicons?domain=${encodeURIComponent(
        url,
      )}&sz=24`
    }

    const { data } = await axios.get('/source-discovery/', {
      params: {
        sourceUrl: url,
      },
    })
    let name = ''
    if (data) {
      name = data
    }
    newImportSource.value.name = name
  },
  {
    debounce: 500,
    maxWait: 1000,
  },
)
</script>
