import { generateSearchQuery } from '@app/utils/generateSearchQuery'
import { FieldSafetyNotice } from '@core/domain/models/fieldSafetyNotice.model'
import { Review } from '@core/domain/models/review.model'
import { ArticleMetadata } from '@core/domain/models/reviewItemMetadata/articleMetadata'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import { ReviewItemType } from '@core/domain/types/reviewItemType.type'
import { CochraneApiClient } from '@infrastructure/cochraneApi/cochraneApi.client'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { EuropePmcApiClient } from '@infrastructure/europePmcApi/europePmcApi.client'
import { FsnApiClient } from '@infrastructure/fsnApi/fsnApi.client'
import { OpenAlexApiClient } from '@infrastructure/openAlexApi/openAlexApi.client'
import { PmcApiClient } from '@infrastructure/pmcApi/pmcApi.client'
//import { ProsperoApiClient } from '@infrastructure/prosperoApi/prosperoApi.client.'
import { PubmedApiClient } from '@infrastructure/pubmedApi/pubmedApi.client'

export class AutoImportService {
  constructor(
    private readonly _reviewsRepository: ReviewsRepository,
    private readonly pmcApiClient: PmcApiClient,
    private readonly europePmcApiClient: EuropePmcApiClient,
    private readonly openAlexApiClient: OpenAlexApiClient,
    private readonly pubmedApiClient: PubmedApiClient,
    private readonly fsnApiClient: FsnApiClient,
    //private readonly prosperoApiClient: ProsperoApiClient,
    private readonly cochraneApiClient: CochraneApiClient,
  ) {}

  async *handleWithProgress({ reviewId }: { reviewId: number }) {
    try {
      const review = await this._reviewsRepository.findById(reviewId)
      if (!review) return
      if (
        review.plan?.preset !== ReviewPreset.DEVICE_SPECIFIC_SEARCH &&
        review.plan?.preset !== ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH &&
        review.plan?.preset !== ReviewPreset.MEDICAL_BACKGROUND
      )
        return

      const sourceApis = this.getSources(review.plan.preset)
      const importSourcesMap = this.createImportSourcesMap(review)

      for (const sourceApi of sourceApis) {
        const source = importSourcesMap.get(sourceApi.id as string)!
        yield `Searching in ${source.name} for ${
          source.id === BuiltInImportSourceId.COCHRANE
            ? review.plan.problemName
            : generateSearchQuery(source, review)
        }`
        await this.processSource(sourceApi, review, importSourcesMap)
      }
    } catch (error: any) {
      console.error(
        `Error handling ReviewCreatedEvent: ${error.message}`,
        error.stack,
      )
    }
  }
  async handle({ reviewId }: { reviewId: number }) {
    try {
      const review = await this._reviewsRepository.findById(reviewId)
      if (!review) return
      if (
        review.plan?.preset !== ReviewPreset.DEVICE_SPECIFIC_SEARCH &&
        review.plan?.preset !== ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH
      )
        return

      const sources = this.getSources(review.plan.preset)
      const importSourcesMap = this.createImportSourcesMap(review)

      const tasks = sources.map((source) =>
        this.processSource(source, review, importSourcesMap),
      )
      await Promise.all(tasks)
    } catch (error: any) {
      console.error(
        `Error handling ReviewCreatedEvent: ${error.message}`,
        error.stack,
      )
    }
  }

  private getSources(preset: ReviewPreset) {
    const sources = [
      ...(preset === ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH ||
      preset === ReviewPreset.DEVICE_SPECIFIC_SEARCH
        ? [
            {
              client: this.pubmedApiClient,
              id: BuiltInImportSourceId.PUBMED,
            },
            {
              client: this.openAlexApiClient,
              id: BuiltInImportSourceId.OPEN_ALEX,
            },
            {
              client: this.europePmcApiClient,
              id: BuiltInImportSourceId.EUROPE_PMC,
            },
            {
              client: this.pmcApiClient,
              id: BuiltInImportSourceId.PMC,
            },
          ]
        : []),
      ...(preset === ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH
        ? [
            {
              client: this.fsnApiClient,
              id: BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
            },
          ]
        : []),
      ...(preset === ReviewPreset.MEDICAL_BACKGROUND
        ? [
            /* {
              client: this.prosperoApiClient,
              id: BuiltInImportSourceId.PROSPERO,
            }, */
            {
              client: this.cochraneApiClient,
              id: BuiltInImportSourceId.COCHRANE,
            },
          ]
        : []),
    ]

    return sources
  }

  private createImportSourcesMap(review: Review) {
    return new Map(
      review.plan?.importPlan?.importSources?.map((s) => [s.id, s]),
    )
  }

  private async processSource(
    source: any,
    review: Review,
    importSourcesMap: any,
  ) {
    try {
      const importSource = importSourcesMap.get(source.id)
      const query = generateSearchQuery(importSource, review)
      const url =
        importSource.id === BuiltInImportSourceId.COCHRANE
          ? `https://www.cochranelibrary.com/search?p_p_id=scolarissearchresultsportlet_WAR_scolarissearchresults&p_p_lifecycle=0&_scolarissearchresultsportlet_WAR_scolarissearchresults_searchType=basic&_scolarissearchresultsportlet_WAR_scolarissearchresults_searchBy=2&_scolarissearchresultsportlet_WAR_scolarissearchresults_searchText=${encodeURIComponent(review.plan?.problemName ?? '')}`
          : ''

      let articles: ArticleMetadata[] | FieldSafetyNotice[]
      let queryTranslation: string | undefined

      if (source.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES) {
        articles = await source.client.searchArticles(
          query,
          review.plan?.fsnCountries,
        )
      } else if (
        source.id === BuiltInImportSourceId.PUBMED ||
        source.id === BuiltInImportSourceId.PMC
      ) {
        const result = await source.client.searchArticles(query)
        articles = result.articles
        queryTranslation = result.queryTranslation
      } else {
        articles = await source.client.searchArticles(query)
      }
      let items
      if (importSource.type === ImportSourceType.FIELD_SAFETY_NOTICES) {
        items = articles?.map((article) => ({
          type: ReviewItemType.FieldSafetyNotice,
          ...article,
        }))
      } else {
        items = articles?.map((article) => ({
          type: ReviewItemType.Article,
          ...article,
        }))
      }
      await this._reviewsRepository.importSearch({
        reviewId: review.id,
        date: new Date().toISOString(),
        query:
          importSource.id === BuiltInImportSourceId.COCHRANE
            ? `${review.plan?.problemName} in Record Title`
            : query,
        importSourceId: importSource.id,
        queryTranslation,
        items,
        url,
      })
    } catch (error: any) {
      console.error(
        `Error processing source ${source.name}: ${error.message}`,
        error.stack,
      )
    }
  }
}
