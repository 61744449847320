<template>
  <div class="h-full space-y-5">
    <div class="space-y-2">
      <p class="text-sm text-black/90 font-medium">
        In which of the following countries is the device sold?
      </p>
      <div class="flex flex-wrap gap-2">
        <button
          v-for="country in countries"
          :key="country.code"
          :class="{
            'bg-primary text-white': preset.fsnCountries.includes(country.code),
            'bg-gray-200 text-black': !preset.fsnCountries.includes(
              country.code,
            ),
          }"
          class="p-2 rounded"
          @click="toggleCountry(country.code)"
        >
          <component :is="country.flag" class="inline-block w-6 h-6 mr-2" />
          {{ country.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { CreateReviewKey } from '../../../useCreateProjectReview'
import {
  AustraliaFlag,
  GermanyFlag,
  UKFlag,
  USAFlag,
  FranceFlag,
  CyprusFlag,
  ItalyFlag,
  CanadaFlag,
  BrazilFlag,
  SwitzerlandFlag,
} from '@app/components/Icons/flags'

const { preset, toggleCountry } = injectStrict(CreateReviewKey)

const countries = [
  { name: 'Australia', code: 'au', flag: AustraliaFlag },
  { name: 'Cyprus', code: 'cy', flag: CyprusFlag },
  { name: 'France', code: 'fr', flag: FranceFlag },
  { name: 'Germany', code: 'de', flag: GermanyFlag },
  { name: 'United Kingdom', code: 'gb', flag: UKFlag },
  { name: 'United States', code: 'us', flag: USAFlag },
  { name: 'Italy', code: 'it', flag: ItalyFlag },
  { name: 'Switzerland', code: 'ch', flag: SwitzerlandFlag },
  { name: 'Canada', code: 'ca', flag: CanadaFlag },
  { name: 'Brazil', code: 'br', flag: BrazilFlag },
]
</script>
