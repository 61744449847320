<template>
  <Editor
    :is-expanded="isExpanded"
    :provider="dataExtractionProvider"
    :user-name="userName"
    :user-color="color"
    :disabled="isReviewReadonly"
    :field="'study::' + study.id + '|attribute::' + id"
    placeholder="Enter the information extracted from the record. Use a dash '-' if the record does not contain information."
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import Editor from '../../components/Editor.vue'

defineProps<{
  isExpanded: boolean
  isReviewReadonly: boolean
  placeholder?: string
  id: string
  study: ReviewItem
}>()
const { user, color } = injectStrict(AuthKey)
const userName = computed(
  () => user.value?.firstName + ' ' + user.value?.lastName,
)
const { dataExtractionProvider } = injectStrict(ReviewKey)
</script>
