<template>
  <div class="w-full -6 flex items-center gap-4 mb-10">
    <div class="inline-flex text-xl font-bold leading-5 text-blue-900">
      Teams
    </div>
    <Modal>
      <template #activator="{ show }">
        <button
          class="bg-primary text-white font-medium tracking-tighter px-3.5 py-2.5 rounded-md inline-flex gap-1 items-center shadow-xs hover:brightness-110"
          @click="show"
        >
          <UsersIcon class="w-5 h-5" />
          Create team
        </button>
      </template>
      <template #content="{ hide }">
        <div class="w-[548px] my-0 relative pt-10">
          <div class="flex justify-end items-end px-6">
            <button class="absolute right-4 top-4" @click="hide">
              <XIcon class="w-6 h-6" />
            </button>
          </div>
          <div class="space-y-10">
            <div class="flex flex-col justify-center items-center gap-2">
              <UsersIcon class="w-8 h-8 text-primary" />

              <p
                class="text-blue-900 text-xl font-semibold leading-6 tracking[-0.4px]"
              >
                Create team
              </p>
            </div>

            <div class="px-10 pb-10 space-y-5">
              <TextInput
                ref="userFristNameInput"
                v-model="createTeamModel.name"
                type="text"
                label="Team name"
                placeholder="Team name"
                :is-required="true"
              />

              <div class="flex justify-between gap-4">
                <button
                  class="w-full bg-white text-primary border border-primary rounded-md text-base font-medium py-2 px-4 hover:brightness-110"
                  @click="hide"
                >
                  Cancel
                </button>
                <button
                  class="w-full bg-primary text-white rounded-md text-base font-medium py-2 px-4 hover:brightness-110"
                  @click="createTeam(hide)"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>

  <table
    class="w-full divide-y divide-slate-200 overflow-hidden drop-shadow sm:rounded-lg"
    aria-describedby="users table"
  >
    <thead class="bg-slate-100">
      <tr>
        <th
          v-for="header in ['Name']"
          :key="'header-' + header"
          class="text-left text-slate-500 px-6 py-3 text-sm font-semibold"
        >
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-slate-200 bg-white">
      <tr v-for="team in teams" :key="'user-' + team.id">
        <td class="py-4 px-6 text-slate-700">
          {{ team.name }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { TeamsServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { Team } from '@core/domain/models/team.model'
import { UsersIcon } from '@heroicons/vue/24/outline'
import { isAxiosError } from 'axios'
import { onMounted, ref } from 'vue'

const teamsService = injectStrict(TeamsServiceKey)
const teams = ref<Team[]>()
const createTeamModel = ref<{ name: string }>({ name: '' })
const loading = useLoading()
const snackbar = useSnackbar()
async function createTeam(cb: () => void) {
  loading.start()
  try {
    const data = { name: createTeamModel.value.name }
    await teamsService.createTeam(data)
    reset()
    await fetchTeams()
    cb()
    snackbar.success('Team created successfully')
  } catch (e) {
    if (isAxiosError(e)) {
      snackbar.error(e.response?.data.message)
    } else {
      snackbar.error('An error occurred')
    }
  } finally {
    loading.stop()
  }
}

function reset() {
  createTeamModel.value = { name: '' }
}

async function fetchTeams() {
  teams.value = await teamsService.findTeams()
}

onMounted(async () => await fetchTeams())
</script>
