<template>
  <router-link
    v-if="
      userCanAccess(route.name, user as User) &&
      (!route.isCuttingEdge || devToolbox.isCuttingEdge)
    "
    v-tooltip.right.light="route.name"
    :to="route.to"
    :class="{
      'bg-black/5 ': isActiveRoute,
    }"
    class="rounded-lg hover:bg-black/5 p-3 block text-primary"
  >
    <slot :route="route" :is-active-route="isActiveRoute">
      <component :is="route.icon" class="w-[24px] h-[24px]" />
    </slot>
  </router-link>
</template>
<script lang="ts" setup>
import { AuthKey, DevToolboxKey } from '@app/injectionKeys'
import {
  userCanAccess,
  navigation,
  RouteName,
  NagivationEntry,
} from '@app/router'
import { injectStrict } from '@app/utils/injectStrict'
import { User } from '@core/domain/models/user.model'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const props = defineProps<{ routeName: RouteName }>()
const devToolbox = injectStrict(DevToolboxKey)
const route = computed<NagivationEntry>(
  () => navigation.find((r) => r.name === props.routeName)!,
)
const { user } = injectStrict(AuthKey)
const vueRoute = useRoute()

const isActiveRoute = computed(() => vueRoute.name === route.value.name)
</script>
