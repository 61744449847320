<template>
  <div
    :id="importSource.id"
    class="space-y-6 px-3 py-7"
    :data-navigation="importSource.name"
  >
    <div class="">
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-2">
          <div
            class="flex items-center gap-2 text-blue-800 text-base font-semibold"
          >
            <ImportFromSource
              :import-source="importSource"
              :icon-url="importSource.iconUrl"
            ></ImportFromSource>
          </div>
        </div>

        <div class="flex gap-6">
          <button
            v-if="
              (review.searchesBySource.value[importSource.id]?.length ?? 0) <=
                0 && !review.isPlanReadonly.value
            "
            class="bg-red-500 px-2 py-1 rounded"
            @click="removeImportSourceFromPlan(importSource.id)"
          >
            <TrashIcon class="w-4 h-4 text-white" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="
        isSelected && review.searchesBySource.value[importSource.id]?.length > 0
      "
    >
      <table
        aria-describedby="searches tables"
        class="w-full border-collapse border-spacing-7"
      >
        <thead>
          <tr class="text-sm border-b border-slate-400">
            <th class="text-left align-top w-[57px]">
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Search
              </div>
            </th>
            <th class="text-left align-top w-[83px]">
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Date
              </div>
            </th>
            <th
              v-if="importSource.id === BuiltInImportSourceId.CITATION_SEARCH"
              class="text-left align-top w-12"
            >
              <div class="p-2 text-primary">Parent</div>
            </th>
            <th
              v-if="
                importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                importSource.id !== BuiltInImportSourceId.CITATION_SEARCH &&
                importSource.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES
              "
              class="text-left align-top w-[112px]"
            >
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Search details
              </div>
            </th>
            <th
              v-if="
                importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
              "
              class="text-left align-top"
            >
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Query
              </div>
            </th>

            <th class="text-left align-top">
              <div
                v-if="
                  importSource.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES
                "
                class="px-2 pb-2 text-blue-800 font-medium text-xs"
              >
                Databases
              </div>
            </th>
            <th class="align-top text-left w-[59px]">
              <div class="px-2 pb-2 text-blue-800 font-medium text-xs">
                Results
              </div>
            </th>
            <th class="text-left align-top w-8">
              <div class="px-2 pb-2"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(search, index) in getSourceSearches(importSource.id)"
            :key="importSource.id + index"
          >
            <SearchRow
              :search="search"
              :search-number="index + 1"
              :import-source="importSource"
            />
          </template>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="w-full text-slate-500 text-sm font-[400] px-2 py-6 bg-slate-50 border border-slate-200 rounded-xl flex flex-col items-center text-center justify-center gap-4"
    >
      <SearchNotFoundIcon />
      <div class="flex flex-col items-center gap-2">
        <p
          v-if="
            review.entity.value.plan?.lockState !== ReviewLockState.LOCKED &&
            importSource.id === BuiltInImportSourceId.HAND_SEARCH
          "
        >
          You haven’t imported any record yet.
        </p>
        <p v-else>You haven’t imported any search yet.</p>
      </div>
    </div>

    <slot
      v-if="
        review.entity.value.plan?.lockState !== ReviewLockState.LOCKED &&
        importSource.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES
      "
      name="bottom"
    />
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { Search } from '@core/domain/models/search.model'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { computed } from 'vue'
import { ImportSource } from '@core/domain/models/import-source.model'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'
import SearchRow from './ImportSourceCard/SearchRow.vue'
import SearchNotFoundIcon from '@app/components/Icons/SearchNotFoundIcon.vue'
import ImportFromSource from './ImportFromSource.vue'

const props = defineProps<{
  importSource: ImportSource
  iconUrl?: string
  query?: string
}>()

const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)
const isSelected = computed(() =>
  review.entity?.value?.plan?.importPlan?.importSources?.some(
    (ri) => ri.id === props.importSource.id,
  ),
)

const emit = defineEmits<(e: 'importSourceRemoved') => void>()

async function removeImportSourceFromPlan(sourceId: string) {
  try {
    loading.start()
    emit('importSourceRemoved')
    await review.removeImportSourceFromPlan(sourceId)
    snackbar.show(SnackbarState.SUCCESS, 'Source removed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

function getSourceSearches(importSourceId: string): Search[] {
  return review.searchesBySource.value[importSourceId] ?? []
}
</script>
