<template>
  <div class="grid grid-cols-[2fr_4fr_100px] w-full items-center">
    <TextInput
      v-model="updatedAttribute.label"
      :disabled="review.isPlanReadonly.value"
      placeholder="Etiology"
      class="h-full"
      editor-classes="h-full"
      input-classes=" group-last:hover:border-slate-300 hover:border-slate-300 hover:border-b-transparent hover:border-r-transparent focus:ring-1 focus:ring-inset group-last:focus:border-blue-500 group-first:rounded-tl-md rounded-none border-b-transparent border-r-transparent group-last:border-b group-last:border-slate-300 group-last:rounded-bl-md   group-last:hover:border-blue-500  group-last:focus-ring-1 group-last:focus-ring-inset group-last:border-r-transparent group-last:hover:border-r-transparent"
      @enter="editAttributeStructure()"
      @blur="editAttributeStructure()"
    />
    <TextInput
      v-model="updatedAttribute.question"
      :disabled="review.isPlanReadonly.value"
      class="h-full"
      editor-classes="h-full"
      input-classes="group-last:hover:border-slate-300  hover:border-slate-300 hover:border-b-transparent hover:border-slate-300 hover:border-b-transparent focus:ring-1 focus:ring-inset group-last:focus:border-blue-500 group-first:rounded-tr-md rounded-none border-b-transparent group-last:rounded-br-md group-last:border-slate-300 group-last:hover:border-blue-500  group-last:focus-ring-1 group-last:focus-ring-inset group-last:border-l"
      placeholder="What is the cause or origin of the disease?"
      @enter="editAttributeStructure()"
      @blur="editAttributeStructure()"
    />
    <div class="flex items-center px-2 gap-2">
      <button
        v-tooltip="
          !updatedAttribute.shouldUseAi
            ? `Enabling this option allows the automatic usage of AI to answer this question when an item is marked as included during the screening process.`
            : ''
        "
        @click.stop="toggleAiUse"
      >
        <SparklesIcon
          class="w-5 h-5"
          :class="{
            'text-green-600': updatedAttribute.shouldUseAi,
          }"
        />
      </button>
      <button
        v-if="!review.isPlanReadonly.value"
        @click="emit('deleteAttributeStructure', structure.id)"
      >
        <TrashIcon class="w-4 h-4 text-red-600" />
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { ref, toRaw } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { deepEqual } from '@app/utils/objects'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import SparklesIcon from '@app/components/Icons/SparklesIcon.vue'

const props = defineProps<{
  structure: AttributeStructure
  questionId: number
}>()

const emit =
  defineEmits<(e: 'deleteAttributeStructure', structureId: string) => void>()
const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()
const updatedAttribute = ref<AttributeStructure>(
  structuredClone(toRaw(props.structure)),
)
async function editAttributeStructure() {
  if (deepEqual(props.structure, updatedAttribute.value)) return

  try {
    loading.start()
    await review.editAttributeStructure(
      review.entity.value.id,
      updatedAttribute.value,
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function toggleAiUse() {
  loading.start()
  try {
    await review.editAttributeStructure(review.entity.value.id, {
      ...updatedAttribute.value,
      shouldUseAi: !updatedAttribute.value.shouldUseAi,
    })
    updatedAttribute.value.shouldUseAi = !updatedAttribute.value.shouldUseAi
  } finally {
    loading.stop()
  }
}
</script>
