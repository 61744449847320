<template>
  <Modal>
    <template #activator="{ show }">
      <button
        class="bg-primary text-white font-medium tracking-tighter px-3.5 py-2.5 rounded-md inline-flex gap-1 items-center shadow-xs hover:brightness-110"
        @click="show"
      >
        <UserPlusIcon class="w-5 h-5" />
        <span class="">Create user</span>
      </button>
    </template>
    <template #content="{ hide }">
      <div class="w-[548px] my-0 relative pt-10">
        <div class="flex justify-end items-end px-6">
          <button class="absolute right-4 top-4" @click="hide">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="space-y-8">
          <div class="flex flex-col justify-center items-center gap-2">
            <UserPlusIcon class="w-8 h-8 text-primary" />
            <p
              class="text-blue-900 text-xl font-semibold leading-6 tracking[-0.4px]"
            >
              Create user
            </p>
          </div>
          <div class="px-10 pb-10 space-y-6">
            <TextInput
              ref="userFristNameInput"
              v-model="createUserModel.firstName"
              type="text"
              label="First name"
              placeholder="First name"
              :is-required="true"
            />
            <TextInput
              ref="userLastNameInput"
              v-model="createUserModel.lastName"
              type="text"
              label="Last name"
              placeholder="Last name"
              :is-required="true"
            />
            <TextInput
              ref="userEmailInput"
              v-model="createUserModel.email"
              type="text"
              label="Email"
              placeholder="Email"
              :rules="[(v) => !!v || 'please enter an email']"
              :is-required="true"
            />
            <TextInput
              ref="userPasswordInput"
              v-model="createUserModel.password"
              type="password"
              label="Password"
              placeholder="Password"
              :rules="[(v) => !!v || 'please enter a password']"
              :is-required="true"
            />

            <Combobox
              v-model="createUserModel.role"
              :display-value="(u: Role) => rolesText[u]"
              :filter="(u, q) => u.toLowerCase().includes(q.toLowerCase())"
              label="Role"
              placeholder="Role"
              :items="[Role.USER, Role.ADMIN, Role.READ_ONLY]"
            />

            <div class="flex flex-row justify-between gap-4">
              <button
                class="w-full bg-white text-primary border border-primary rounded-md tracking-tighter text-base font-medium py-2 px-4 hover:brightness-110"
                @click="hide"
              >
                Cancel
              </button>
              <button
                class="w-full bg-primary text-white rounded-md text-base font-medium tracking-tighter py-2 px-4 hover:brightness-110"
                @click="create(hide)"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { Role } from '@core/domain/types/role.type'
import useUsers from '@app/composables/use-users'
import { hideAll } from 'tippy.js'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'
import UserPlusIcon from '@app/components/Icons/UserPlusIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import XIcon from '@app/components/Icons/XIcon.vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import Combobox from '@app/components/Global/Combobox/Combobox.vue'

const rolesText = {
  [Role.USER]: 'User',
  [Role.ADMIN]: 'Admin',
  [Role.READ_ONLY]: 'Read Only',
}
interface CreateUserModel {
  email: string
  password: string
  role: Role
  firstName: string
  lastName: string
}

const userEmailInput = ref<InstanceType<typeof TextInput>>()
const userPasswordInput = ref<InstanceType<typeof TextInput>>()

const snackbar = useSnackbar()
const loading = useLoading()
const createUserModel = ref<CreateUserModel>({
  email: '',
  role: Role.USER,
  password: '',
  firstName: '',
  lastName: '',
})
const users = useUsers()

async function create(cb: () => void = () => {}) {
  if (!validate()) return
  try {
    loading.start()
    await users.create(createUserModel.value)
    snackbar.show(SnackbarState.SUCCESS, 'User created successfully')
    reset()
    hideAll()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
    cb()
  }
}
function IsValidEmail(email: string) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/

  return emailPattern.test(email)
}

function validate() {
  const isEmailValid = IsValidEmail(createUserModel.value.email)
  const isPasswordValid = userPasswordInput.value?.validate()
  return isEmailValid && isPasswordValid
}

function reset() {
  createUserModel.value = {
    email: '',
    role: Role.USER,
    password: '',
    firstName: '',
    lastName: '',
  }
}
</script>
