<template>
  <div class="flex items-center overflow-hidden">
    <button
      v-tooltip="
        review.entity.value.studies.length === 0
          ? 'You need to import at least one study'
          : 'Search'
      "
      class="items-center block"
      :class="[
        review.entity.value.studies.length === 0 ? 'cursor-not-allowed' : '',
      ]"
      @click="toggleSearchExpanded"
    >
      <SearchIcon class="w-5 h-auto stroke-2 text-white" />
    </button>

    <TransitionExpand axis="x">
      <div v-show="isSearchExpanded" class="flex items-center gap-2 h-[23px]">
        <input
          ref="searchInput"
          v-model="query"
          type="text"
          :placeholder="`${
            review.filtering.highlightOnly.value ? 'Highlight' : 'Search'
          }: skin irritation; electrode; ...`"
          class="bg-transparent placeholder:text-white/70 border-black/20 px-2 focus:outline-hidden"
          :disabled="review.entity.value.studies.length === 0"
          :class="[
            review.entity.value.studies.length === 0
              ? 'cursor-not-allowed'
              : '',
          ]"
        />
        <button
          class="rounded-smp-0.5"
          @click="
            review.entity.value.studies.length === 0
              ? ''
              : (review.filtering.highlightOnly.value =
                  !review.filtering.highlightOnly.value)
          "
        >
          <PaintBrushIconSolid
            v-if="review.filtering.highlightOnly.value"
            class="w-5 h-auto"
          />
          <PaintBrushIconOutline v-else class="w-5 h-auto" />
        </button>
      </div>
    </TransitionExpand>
  </div>
</template>

<script lang="ts" setup>
import { ReviewKey } from '../../use-review'
import { injectStrict } from '@app/utils/injectStrict'
import SearchIcon from '@app/components/Icons/SearchIcon.vue'
import { createDebounce } from '@app/utils/debounce'
import { ref, watch } from 'vue'
import { PaintBrushIcon as PaintBrushIconSolid } from '@heroicons/vue/24/solid'
import { PaintBrushIcon as PaintBrushIconOutline } from '@heroicons/vue/24/outline'
import { TransitionExpand } from '@morev/vue-transitions'

const review = injectStrict(ReviewKey)
const query = ref('')

const debounce = createDebounce(500)
watch(
  () => query.value,
  (v) =>
    debounce(() => {
      review.filtering.query.value = v
    }),
)

const searchInput = ref<HTMLInputElement | null>(null)

const isSearchExpanded = ref(false)

function toggleSearchExpanded() {
  isSearchExpanded.value = !isSearchExpanded.value
  if (isSearchExpanded.value) {
    setTimeout(() => {
      searchInput.value?.focus()
    }, 0)
  }
}
</script>
