<template>
  <button
    class="flex gap-2 items-center"
    :class="{ 'cursor-not-allowed': disabled }"
    @click.stop="disabled ? '' : (model = !model)"
  >
    <div
      class="block rounded-full relative"
      :class="[
        model ? 'bg-green-600' : ' bg-gray-300',
        {
          'w-8 h-5': size === '',
          'w-10 h-7': size === 'lg',
          'bg-gray-300 ': disabled,
        },
      ]"
    >
      <div
        class="absolute top-1 w-3 h-3 rounded-full transition drop-shadow"
        :class="[
          model ? ' bg-white ' : '',
          model && size === 'lg' ? 'translate-x-3/4' : 'left-1',
          model && size === '' ? 'translate-x-full' : '',
          {
            'w-3 h-3 left-1': size === '',
            'w-5 h-5': size === 'lg',
            'opacity-50': disabled,
          },
        ]"
      />
    </div>
    <slot />
  </button>
</template>
<script lang="ts" setup>
withDefaults(
  defineProps<{
    size?: 'lg' | ''
    disabled?: boolean
  }>(),
  {
    size: '',
  },
)

const model = defineModel<boolean>()
</script>
