<template>
  <div class="flex-grow h-full space-y-4 mb-6">
    <div class="flex gap-3">
      <div class="mb-4 text-blue-700 font-semibold tracking-tighter text-lg">
        Select a medical indication (e.g., diagnosis of a disease)
      </div>
    </div>
    <div class="overflow-hidden rounded-lg border border-slate-300">
      <table
        class="w-full bg-gray-50 border-collapse"
        aria-describedby="medical background problem purpose"
      >
        <thead class="bg-white sticky top-0 z-10">
          <tr>
            <th
              class="px-4 py-3 text-left text-sm font-bold text-gray-700 w-48 tracking-tighter border-b border-slate-300 rounded-md"
            >
              Purpose
            </th>
            <th
              v-for="(category, index) in conditions"
              :key="index"
              class="p-4 text-left text-sm font-medium text-gray-600 w-32 border-b border-l border-slate-300"
            >
              {{ category }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(action, purposeIndex) in actions" :key="purposeIndex">
            <td
              class="p-4 text-sm text-gray-700 bg-white w-48 border-t border-slate-300"
            >
              {{ action }}
            </td>
            <td
              v-for="(condition, categoryIndex) in conditions"
              :key="categoryIndex"
              class="w-32 border-l border-t border-slate-300 flex-row items-stretch"
            >
              <FloatingTextInput
                :placeholder="`${preset.problem} name`"
                :value="preset.problemName"
                @save="setConditionName"
              >
                <template #activator="{ show }">
                  <button
                    class="w-full min-h-20 text-sm flex items-center justify-center transition-all duration-200 group focus:outline-hidden"
                    :disabled="!isSelectable(condition, action)"
                    :class="buttonClass(condition, action)"
                    @click.stop="[selectOption(action, condition), show()]"
                  >
                    <span v-if="isSelected(action, condition)" class="truncate">
                      {{ preset.problemName }}
                    </span>
                    <span
                      v-else-if="isSelectable(condition, action)"
                      class="opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                    >
                      Select
                    </span>
                  </button>
                </template>
              </FloatingTextInput>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { CreateReviewKey } from '../../../useCreateProjectReview'
import { computed } from 'vue'

const { preset } = injectStrict(CreateReviewKey)

enum Condition {
  Disease = 'Disease',
  Injury = 'Injury',
  Disability = 'Disability',
}

enum Action {
  Prevention = 'Prevention',
  Prediction = 'Prediction',
  Prognosis = 'Prognosis',
  Diagnosis = 'Diagnosis',
  Treatment = 'Treatment (management)',
  Alleviation = 'Alleviation',
  Monitoring = 'Monitoring',
  Compensation = 'Compensation',
}

const conditions = Object.values(Condition)
const actions = Object.values(Action)

const selectableOptions: Record<Condition, Action[]> = {
  [Condition.Disease]: [
    Action.Prevention,
    Action.Prediction,
    Action.Prognosis,
    Action.Diagnosis,
    Action.Treatment,
    Action.Alleviation,
    Action.Monitoring,
  ],
  [Condition.Injury]: [
    Action.Diagnosis,
    Action.Treatment,
    Action.Compensation,
    Action.Alleviation,
    Action.Monitoring,
  ],
  [Condition.Disability]: [
    Action.Diagnosis,
    Action.Treatment,
    Action.Compensation,
    Action.Alleviation,
    Action.Monitoring,
  ],
}

const isSelected = computed(
  () =>
    (action: Action, condition: Condition): boolean =>
      preset.value?.purpose === action && preset.value?.problem === condition,
)

const selectOption = (action: Action, condition: Condition): void => {
  if (!preset.value) {
    console.error('Preset is not initialized')
    return
  }

  if (isSelectable(condition, action)) {
    preset.value.problem = condition
    preset.value.purpose = action
  }
}

const isSelectable = (condition: Condition, action: Action): boolean => {
  return selectableOptions[condition]?.includes(action) ?? false
}

const buttonClass = computed(
  () =>
    (condition: Condition, action: Action): string => {
      if (isSelected.value(action, condition)) {
        return 'bg-primary text-white ring-1 ring-primary ring-offset-1'
      }
      return isSelectable(condition, action)
        ? 'bg-[#DBEAFE] hover:bg-primary/40 cursor-pointer'
        : 'bg-gray-50 text-gray-300 cursor-default'
    },
)

async function setConditionName(condition: string) {
  preset.value.problemName = condition
}
</script>
